/* -------------------------Screens larger than 950px----------------------- */
.body {
    background-color: black;
    color: white;
    height: calc(100vh - 120px);
    /* display: flex; */
    padding-top: 120px;
    background-image: url("../../media/PortfolioBG.png");
    background-repeat: repeat;
    overflow: auto;
}

.section {
    background-color: rgba(38, 38, 38, 0.75);
    margin: 10rem;
    margin-top: 3rem;
    border-radius: 20px;
    padding: 2rem;
    height: fit-content;
    min-height: 80%;
    overflow-y: visible;
}

.sectionBody {
    display: flex;
}

.leftBody {
    width: 50%;
    position: relative;
    z-index: 20;
    margin-top: 5rem;
    margin-left: 5rem;
}

.title {
    font-size: 7vh;
    font-weight: bolder;
    text-align: center;
    position: inline;
}

.border {
    border-radius: 20px;
    padding: 1rem;
    width: 80%;
    margin-left: 5rem;
}

.subTitle {
    font-size: 4vh;
}

.bio {
    font-size: 2vh;
    padding: 2rem;
    padding-left: 0rem;
    padding-bottom: 0;
    overflow: auto;
}

.rightBody {
    z-index: 20;
    width: 50%;
    border-radius: 10px;
    margin-top: 10rem;
}

.imgContainer {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
}

.thumbnail {
    scale: 1.5;
    border-radius: 10px;
    max-width: 90%;
}

.footer {
    text-align: center;
    font-size: 4vh;
}

a.link {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

a:hover {
    color: rgba(94, 23, 235, 1);
  }

.dot {
    background-color: #5E17EB;
    width: .9rem;
    height: .9rem;
    border-radius: 10%;
    margin-right: 1rem;
}

@media screen and (max-width: 1500px)
{
    .leftBody {
        width: 50%;
        position: relative;
        z-index: 20;
        margin-top: 5rem;
        margin-left: 2rem;
    }

    .rightBody {
        z-index: 20;
        width: 50%;
        border-radius: 10px;
        margin-top: 10rem;
    }

    .bio {
        font-size: 1.2rem;
        padding: 2rem;
        padding-left: 0rem;
        padding-bottom: 0;
        overflow: auto;
    }

    .section {
        background-color: rgba(38, 38, 38, 0.75);
        margin: 10rem;
        margin-top: 1rem;
        border-radius: 20px;
        padding: 2rem;
        height: fit-content;
        min-height: 80%;
        overflow-y: auto;
    }
}

/* -------------------------Screens smaller than 950px----------------------- */
@media screen and (max-width: 950px)
{
    .section {
        background-color: rgba(38, 38, 38, 0.75);
        margin: 2rem;
        margin-top: 2rem;
        border-radius: 20px;
        padding: 1rem;
        height: fit-content;
        overflow-y: auto;
    }

    .sectionBody {
        display: block;
        padding: 0rem;
    }
    
    .leftBody {
        margin-top: 2rem;
        width: 100%;
        margin-left: 0rem;
    }

    .border {
        border-radius: 20px;
        padding: 1rem;
        width: 100%;
        margin-left: 0rem;
    }

    .rightBody {
        width: 100%;
        padding-bottom: 2rem;
        margin-top: 2rem;
    }

    .title {
        padding-bottom: 0rem;
        padding-top: 1rem;
        font-size: 7vh;
        font-weight: bolder;
        text-align: center;
        position: relative;
        left: 0%;
    }

    .subTitle {
        margin-left: 1rem;
        margin-right: 1rem;
        text-align: center;
    }
    
    .bio {
        font-size: 2vh;
        overflow: auto;
        padding: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 0;
    }
    .thumbnail {
        scale: .8;
        border-radius: 10px;
    }

    .dot {
        background-color: #5E17EB;
        width: .5rem;
        height: .5rem;
        border-radius: 10%;
        margin-right: 1rem;
    }
}