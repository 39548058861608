@import url('https://fonts.googleapis.com/css?family=Rubik');
* {
  font-family: 'Rubik', sans-serif;
  margin: 0px;
  overflow: hidden;
}

.body {
    background-color: black;
    color: white;
    height: calc(100vh - 120px);
    display: flex;
    padding-top: 120px;
    overflow: hidden;
}

.leftBody {
    border-top-right-radius: 20px;
    width: 55%;
    position: relative;
    z-index: 20;
    min-height: 100vh;
}

.title {
    padding: 15%;
    padding-bottom: 5%;
    font-size: 7vh;
    font-weight: bolder;
}

.subTitle {
    font-size: 4vh;
    padding-left: 15%;
}

.bio {
    font-size: 2vh;
    padding: 15%;
    padding-top: 10%;
}

.rightBody {
    position: relative;
    z-index: 20;
    width: 45%;
    overflow: hidden;
}

/* -------------------------Screens smaller than 450px----------------------- */
@media screen and (max-width: 450px)
{
    .title {
        padding: 15%;
        padding-bottom: 5%;
        font-size: 2rem;
        font-weight: bolder;
    }

    .subTitle {
        font-size: .75rem;
        padding-left: 15%;
    }

    .bio {
        font-size: 1rem;
        padding: 15%;
        padding-top: 10%;
    }
}
